import './Css/App.css';
import { Banner } from './components/Banner';
import { NavBar } from './components/NavBar';
import { Projects } from './components/Projects';
import { Footer } from './components/Footer'
import {Testimonial} from './components/Testimonial';

// import SlickCarousel from './components/SlickCarousel';
// import SlickCarouselLatam from './components/SlickCarouselLatam';

import 'bootstrap/dist/css/bootstrap.min.css';
// import { VideoBackground } from './components/VideoBackground';
import VideoBackground from './components/VideoBackground';

import { Contact } from "./components/Contact";
// import  CenteredContact  from "./components/CenteredContact";
// import { Team } from './components/Team';
// import InfiniteCarousel from './components/InfiniteCarousel'; 



// const cards = [
//   { flag: require('./assets/img/colombia.png'), country: 'Colombia', info:"Bogota" },
//   { flag: require('./assets/img/usa.png'), country: 'Estados Unidos', info:"Atlanta" },
//   // { flag: require('./assets/img/peru.jpg'), country: 'Perú', info:"Lima" },
//   { flag: require('./assets/img/ecuador.jpg'), country: 'Ecuador', info:"Guayaquil" },
//   { flag: require('./assets/img/guatemala.png'), country: 'Guatemala', info:"Guatemala" }
// ];


function App() {
  return (
    <div className="App">
      <NavBar/>
      {/* <BannerWithGifBackground /> */}
      <Banner/>
      <div style={{ marginTop: '1px' }}>
                <Testimonial />
            </div>
      {/* <Testimonial/> */}
      <Projects/>
      {/* <Contact/> */}
      {/* <InfiniteCarousel cards={cards} /> */}
      {/* <Team/>
      <Contact/> */}
      {/* <InfiniteCarousel cards={cards} /> */}
      {/* <SlickCarousel cards={cards} /> */}
      {/* <VideoBackground/> */}
      <Footer/>
    </div>
  );
}

export default App;
