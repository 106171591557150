import React from 'react';
import { Col, Container, Row } from "react-bootstrap";



export const Testimonial = () => {
    
  // const responsive = {
  //     superLargeDesktop: {
  //       // the naming can be any, depends on you.
  //         breakpoint: { max: 4000, min: 3000 },
  //         items: 5
  //     },
  //     desktop: {
  //         breakpoint: { max: 3000, min: 1024 },
  //         items: 3
  //     },
  //     tablet: {
  //         breakpoint: { max: 1024, min: 464 },
  //         items: 2
  //     },
  //     mobile: {
  //         breakpoint: { max: 464, min: 0 },
  //         items: 1
  //     }
  // };

  return (
    <section className="testimonial" id="somos">
      <Container>
          <Row>
            <Col lg={6}>
              <div className="testimonial-content">
                <h3>Quienes somos</h3>
                <blockquote>
                  Atm Net Systems and Solutions es una compania dedicada al campo del autoservicio que ha transformado los modelos convencionales de negocios a traves de la integracion de tecnologia, conocimiento y desarrollo de productos.
                  {/* <cite>FRANCIS VAN PARYS, PRESIDENT AND CEO, GE HEALTHCARE KOREA</cite> */}
                </blockquote>
              </div>
            </Col>
          </Row>
      </Container>
    </section>
  );
}

// export default Testimonial;