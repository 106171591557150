import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import logo from "../assets/img/logo_atm.png";

export const Newsletter = ({ status, message, onValidated }) => {
  // const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const clearFields = () => {
    // setEmail('');
  }

  return (

    <Col lg={12}>
    <div className="newsletter-bx wow slideInUp">
      <Row>
        <Col lg={12} md={6} xl={7} className="text-center">
            {/* <h3>Ideas para Innovar</h3> */}
            <h3>innovación, Seguridad, Desarrollo</h3>
        </Col>
        <Col lg={12} md={6} xl={5} className="text-left">
          <img src={logo} alt="logo"/>
        </Col>
      </Row>
    </div>
  </Col>
  )
}
