import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Newsletter } from "./Newsletter";
import logo from "../assets/img/atmnet.png";
import "../Css/Footer.css"

export const Footer = () => {
  const countries = [
    'Colombia',
    'USA',
    'Guatemala',
    'Ecuador',
  ];

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center mt-4">
          <Newsletter />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              {/* Agrega tus íconos sociales aquí */}
            </div>
          </Col>
        </Row>
        <Row className="py-3 justify-content-center">
          <Col xs={4} sm={3} className="text-center">
            <div className="address-column">
              <h4 className="mb-1 text-white" style={{ fontSize: '18px' }}>Dirección</h4>
              <p className="mt-0 mb-0 text-white">
                1555 Oakbrook Drive<br />
                Suite 105<br />
                Norcross, Georgia<br />
                30093
              </p>
            </div>
          </Col>
          <Col xs={4} sm={3} className="text-center">
            <div className="phone-column">
              <h4 className="mb-1 text-white" style={{ fontSize: '18px' }}>Teléfono</h4>
              <p className="mt-0 mb-0 text-white">+1 (404) 770-449-6545</p>
            </div>
          </Col>
          {/* Nueva columna de Información general */}
          <Col xs={4} sm={3} className="text-center">
            <div className="general-info-column">
              <h4 className="mb-1 text-white" style={{ fontSize: '18px' }}>Información general</h4>
              <p className="mt-0 mb-0 text-white">asaavedra@atmnetss.com</p>
            </div>
          </Col>
          {/* Nueva columna de Presencia en Latam */}
          <Col xs={4} sm={3} className="text-center">
            <div className="general-info-column">
              <h4 className="mb-1 text-white" style={{ fontSize: '18px' }}>Presencia en</h4>
              {countries.map((country, index) => (
                <p key={index} className="mt-0 mb-0 text-white">{country}</p>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="pb-3 justify-content-center">
          <Col className="text-center">
            <p className="text-white">Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
