import { Navbar, Container, Nav } from "react-bootstrap";
import { useState, useEffect } from "react";
// import { HashLink } from 'react-router-hash-link';
import logo from "../assets/img/atmnet.png";
// import { ModalComponent } from './components/ModalComponent'

import { ModalComponent } from "./ModalComponent";

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');

    const [scrolled, seScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scroll) {
                seScrolled(true);
            }else{
                seScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);

    }, [])

    const onUpdateActiveLink = (value) =>{
        setActiveLink(value);
    } 

    return (
        <Navbar expand="lg" className={ scrolled ? "scrolled" : ""}>
            <Container>
                <Navbar.Brand href="#inicio">
                    <img src={logo} alt="Logo"/>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="navbar-toggle-icon" />
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="navbar-text">
                        <Nav.Link href="#inicio" className={activeLink === 'inicio' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('inicio')} >Inicio</Nav.Link>
                        <Nav.Link href="#somos" className={activeLink === 'somos' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('somos')} >Somos</Nav.Link>
                        <Nav.Link href="#project" className={activeLink === 'project' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('project')} >Ideas</Nav.Link>
                        {/* <Nav.Link href="#connect" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')} >Contacto</Nav.Link> */}
                    </Nav>
                    <span className="navbar-text">
                        <div className="social-icon">
                            {/* <a href="#"><img src={navIcon1} alt="" /></a>
                            <a href="#"><img src={navIcon2} alt="" /></a>
                            <a href="#"><img src={navIcon3} alt="" /></a> */}
                        </div>
                    {/* <HashLink to='#connect'> */}
                    {/* <ModalComponent/> */}
                    {/* </HashLink> */}
                    </span>
                        
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}