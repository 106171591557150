// import React from 'react';
import { Col } from "react-bootstrap";

export const InfoCards = ({ title, description, icon }) => {

    return (
        <Col size={6} sm={2} md={4}>
            <div className="service-item">
                <i className="ti-help-alt"> {icon} </i>
                {/* <i class="ti-view-grid" aria-hidden="true"></i> */}
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </Col>
        )
    }
    // <div className={`service-item ${isVisible ? 'active' : ''}`}>