import { Container, Row, Col, Tab } from "react-bootstrap";
// import colorSharp2 from "../assets/img/pag1.png";
import { FaChartColumn, FaBuildingCircleCheck, FaBuildingShield, FaSchool, FaMicrochip } from "react-icons/fa6"; 


import TrackVisibility from 'react-on-screen';
import { InfoCards } from "./InfoCards";


export const Projects = () =>{

    const infocards = [
        {
            title: "Bancos",
            description: "Cajeros automáticos seguros y avanzados que reflejan nuestra constante búsqueda de innovación en servicios financieros.",
            icon: <FaChartColumn />,
        },
        {
            title: "Cajeros Automáticos",
            description: "En Atm Net Systems and Solutions trabajamos con todas las marcas de cajeros automáticos más reconocidas del Mercado. Contamos con distribución de partes, módulos (nuevos, usados, reconstruidos) al igual que con la distribución de partes y consumibles originales y genéricos según los requerimientos de nuestros clientes.",
            icon: <FaMicrochip />,
        },
        {
            title: "School",
            description: "Atm Net Systems es  líder en programas de capacitación para ingenieros enfocados en la atención de cajeros automáticos, desarrollo de programas de mantenimiento y valoración de inventario.",
            icon: <FaSchool />,
        },
        {
            title: "Servicios",
            description: "Unidades interactivas para el control y la seguridad del dinero, Sistemas de reporte el linea",
            icon: <FaBuildingCircleCheck />,
        },
        {
            title: "Seguridad",
            description: "Desarrollo e implementación de sistemas de seguridad  transnacional.",
            icon: <FaBuildingShield />,
        }
    ]

    return (
        <section className="project" id="project">
            <Container>
                <Row>
                    <Col>
                        <TrackVisibility>
                        {({ isVisible }) =>
                        <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                            <h1>IDEAS EXTRAORDINARIAS</h1>
                            <p>Nuestra experiencia y proximidad tanto a los bancos, compañias de mantenimiento y compañias de servicio nos han permitido identificar las necesidades de los mismos para generar soluciones tecnologicas, propietarias, que se adaptean a los requerimientos de nuestros clientes.</p>
                            <Tab.Container id="projects-tabs" defaultActiveKey="first">
                                    <Tab.Pane eventKey="first">
                                        <Row className="row-cards">
                                            {
                                                infocards.map((infocard, index) => {
                                                    return (
                                                    <InfoCards  key={index} {...infocard}/>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Tab.Pane>
                            </Tab.Container>
                        </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}