
import { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import TrackVisibility from 'react-on-screen';
import React from 'react';
// import { VideoBackground } from "./VideoBackground";

import logo from "../assets/img/logo_atm.png";
import video from "../assets/img/background.mp4"
// import '../Css/Banner.css'; 
import '../Css/video.css'; 

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(200 - Math.random() * 110);
    const [index, setIndex] = useState(1);
    const toRotate = ["Banking - Retail"];
    const period = 800;

    useEffect(() => {
        let ticker = setInterval(() => {
        tick();
        }, delta);

        return () => { clearInterval(ticker) };
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting) {
        setDelta(prevDelta => prevDelta / 2);
        }

        // if (!isDeleting && updatedText === fullText) {
        // setIsDeleting(true);
        // setIndex(prevIndex => prevIndex - 1);
        // setDelta(period);
        // } else if (isDeleting && updatedText === '') {
        // setIsDeleting(false);
        // setLoopNum(loopNum + 1);
        // setIndex(1);
        // setDelta(800);
        // } else {
        // setIndex(prevIndex => prevIndex + 1);
        // }
    }

    return (
        <section className="banner" id="inicio">
            {/* <div className="overlay">
                <video src={video} autoPlay loop muted />   */}
                <Container>
                    <Row className="justify-content-center title-banner">
                        <Col xs={12} md={7} xl={5}>
                            <img src={logo}/>
                            {/* <TrackVisibility>
                                {({ isVisible }) =>
                                    <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                                    </div>}
                            </TrackVisibility> */}
                        </Col>

                        <Col xs={12} md={12} xl={12} className="text-center">
                            <TrackVisibility>
                                {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                    <h1><span className="txt-rotate" ><span className="wrap">{text}</span></span></h1>
                                </div>}
                            </TrackVisibility>
                        </Col>
                    </Row>
                </Container>
            {/* </div> */}
        </section>
    )
}